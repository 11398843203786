<template>
  <div>
    <v-row>
      <v-col> <p class="text-2xl mb-6">Reportes</p> </v-col>
      
      <v-col class="auto">
        <v-row>
          <v-text-field v-model="filtroText" dense outlined class="mr-1" label="Buscar"></v-text-field>
          <v-select
            dense
            outlined
            item-text="descripcion"
            item-value="id"
            v-model="categoriaSelec"
            :items="categorias"
            attach
            chips
            label="Categorias"
            multiple
          ></v-select>
        </v-row>
      </v-col>
    </v-row>
    
    <div v-for="(cat, inde) in groupedCategorias" :key="inde">
      <v-divider class="my-2"></v-divider>
      <p class="mt-2">{{ CategoriaFind(cat.key).descripcion  }}</p>
      <v-row class="match-height">
        <v-col cols="12" sm="6" md="4" v-for="(item, index) in cat.items" :key="index">
          
            <v-card @click="irReporte(item)">
              <v-card-text class="pb-0 mb-0">
                <v-row>
                  <v-col>
                    <v-card-subtitle class="pl-0 mb-0 pb-0"> {{ cat.descripcion }} </v-card-subtitle>
                    <v-card-title class="pl-0 pt-1"> {{ item.nombre }} </v-card-title>
                  </v-col>
                  <v-col cols="auto" class="my-0 py-0">
                    <v-icon size="70" class="mr-4">
                      {{ item.imgicon ? item.imgicon : icons.mdiChartBoxMultiple }}
                    </v-icon>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text class="mt-0 pt-0">
                <p>{{ item.descripcion }}</p>
              </v-card-text>
            </v-card>
 
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiPoll,
  mdiLabelVariantOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiShopping,
  mdiCashRegister,
} from '@mdi/js'

import MaestroService from '@/api/servicios/MaestroService'
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
// demos

export default {
  components: {},
  setup() {
    const mdiChartBoxMultiple =
      'M4 20H16V22H4C2.9 22 2 21.1 2 20V7H4M22 4V16C22 17.1 21.1 18 20 18H8C6.9 18 6 17.1 6 16V4C6 2.9 6.9 2 8 2H20C21.1 2 22 2.9 22 4M12 8H10V14H12M15 6H13V14H15M18 11H16V14H18Z'
    
    const datos = ref([])
    const categorias = ref([])
    const cargando = ref(false)
    const filtroText = ref('')
    const categoriaSelec = ref(null)
    onBeforeMount(() => {
      cargarReportes()
    })
    const irReporte = item => {
      if (item.tipo_reporte == 1){
        router.push({ name: item.url })
      }else{
        router.push({ name: 'administracion-archivo-reportesview', params: { id: item.id } })
      }
     
      
    }

    const filtrarReportes = cat => {
      return datos.value
        .filter(it => it.id_categoria == cat)
        .filter(it => store.state.user.accion_modulo.includes(it.id_accion_modulo))
    }

    const CategoriaFind =(_id)=>{
      return categorias.value.find(item=>item.id == _id)
    }

    watch(categoriaSelec,()=>{
      FitrarCategorias()
    })
    watch(filtroText,()=>{
      FitrarCategorias()
    })

    const FitrarCategorias = () => {
      let _datos =  datos.value.filter(item=>(!categoriaSelec.value || categoriaSelec.value.length == 0 || categoriaSelec.value.includes(item.id_categoria) ))
      _datos =  _datos.filter(item=>(
        item.nombre.toUpperCase().includes(filtroText.value.toUpperCase())
        ||  filtroText.value == ''
        ))

       groupDataByCategoria(_datos)
    }


    const groupedCategorias = ref([])
    const groupDataByCategoria = (_datos) => {
      groupedCategorias.value = _datos.reduce((acc, cur) => {
        var val = cur.id_categoria
        var index = acc.findIndex(function (x) {
          return x.key == val
        })
        if (index < 0) {
          index =
            acc.push({
              key: val,
              items: [],
            }) - 1
        }
        acc[index].items.push(cur)
        return acc
      }, [])
    }
    const cargarReportes = () => {
      cargando.value = true
      MaestroService.reportesListar()
        .then(response => {
          console.log(response)
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            datos.value = response.data.datos
            categorias.value = response.data.categorias
            groupDataByCategoria(datos.value)
          }

        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          cargando.value = false
        })
    }
    return {
      icons: {
        mdiHelpCircleOutline,
        mdiShopping,
        mdiCashRegister,
        mdiChartBoxMultiple,
      },
      cargarReportes,
      cargando,
      categorias,
      datos,
      irReporte,
      filtrarReportes,
      filtroText,
      categoriaSelec,
      groupedCategorias,
      CategoriaFind
    }
  },
}
</script>
